// @font-face {
// 	font-family: 'Custom Registered Sign';
// 	src: url('~@fontsource/noto-sans-tc/files/noto-sans-tc-119-400-normal.woff2') format('woff2'), url('~@fontsource/noto-sans-tc/files/noto-sans-tc-all-400-normal.woff') format('woff');
// 	unicode-range: U+00AE;
// }

@mixin fontFace($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$src}.ttf') format('truetype');
      // Safari, Android, iOS
    font-style: $style;
    font-weight: $weight;
  }
}

@include fontFace('Montserrat', '/assets/fonts/Montserrat-Regular', normal, normal);
@include fontFace('Montserrat', '/assets/fonts/Montserrat-Medium', normal, 600);
@include fontFace('Montserrat', '/assets/fonts/Montserrat-Bold', normal, 700);

$fontPrimary: "Montserrat", "Noto Sans TC", "Open Sans", -apple-system,
BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
"Fira Sans", "Droid Sans", "Helvetica Neue",
"Microsoft JhengHei", 微軟正黑體,
sans-serif;

// /Users/helenlaw/app-bar-project/fish-qc-web-react/public/fonts/Montserrat-Regular.ttf