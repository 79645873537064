@import '/src/styles/AppDesign.scss';

.redeem-popup {
	display: flex;
	flex-direction: column;
	align-items: center;

	.round-button {
		width: 70%;
		margin: 24px 0;
		font-weight: 700;
        background-color: #76e2d2;
        color: #fff;
		box-shadow: 0 0 10px 2px #76e2d2;
	}
}

.redeem-popup-content {
	max-width: 70%;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		color: #593565;
	}

	p {
		color: #949494;
		margin: 12px 0;
	}

	input {
		appearance: none;
		width: 100%;
		height: 70px;
		padding: 0 3%;
		box-sizing: border-box;
		border: 0;
		outline: 0;
		background-color: #eee;
		border-radius: 10px;
	}
}
