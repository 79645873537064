@import '/src/styles/AppDesign.scss';

.formContainer {
  margin: 60px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}

.generalInputContainer {
  max-width: 70vw;
  width: 500px;
}

.generalInput {
  width: 100%;

  input, select {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #FBFAFF;
    padding:  16px 22px;
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: 'center';
    border-width: inherit;
    text-decoration: none;
    outline: inherit;
    box-sizing: border-box;
  }
}

.loginInputItem {
  position: relative;
  margin: 13px 0;

  display: flex;
  align-items: center;
}

.signupInputItem {
  position: relative;
  margin: 13px 0 40px;

  display: flex;
  align-items: center;
}

.loginInput {
  height: 56px;
  &:last-child {
    flex: 1 0 60%;
  }
  &:nth-child(2) {
    margin-left: 12px;
  }

  position: relative;
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 19px;
    left: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  input, select {
    appearance: none;
    box-sizing: border-box;
    padding: 0 24px 0 calc(24px + 16px + 16px);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    &::placeholder {
      color: #5F5D70;
    }
  }
}
.loginInputEmail::before {
  background-image: url('../../assets/images/userIcon.png');
}
.loginInputPassword::before {
  background-image: url('../../assets//images//passwordIcon.png');
}
.editProfileInputEmail::before {
  background-image: url('../../assets/images/emailIcon.png');
}
.editProfileInputPhone::before {
  background-image: url('../../assets/images/phoneIcon.png');
}

.inputItem {
  position: relative;
  margin: 13px 0;
}
