@import '/src/styles/AppDesign.scss';

.product-details-comment-card {
	margin: 24px 0;
	width: 100%;
	padding: 13px 9px;
	box-sizing: border-box;
	border-radius: 8px;
	box-shadow: 1px 4px 13px 0 #11154c33;
	display: grid;
	grid-template-columns: 30px 1fr;
	grid-template-rows: 10px 16px 1fr 16px;
	column-gap: 12px;
	row-gap: 8px;
}

.product-details-comment {
	&-avatar {
		width: 30px;
		grid-row: 1 / -1;

		img {
			width: 30px;
			height: 30px;
			object-fit: contain;
			border-radius: 100%;
		}
	}

	&-name {
		font-size: 10px;
		font-weight: 600;
		color: #13c6c6;
	}

	&-content {
		line-height: 1.5;
		color: #696868;

		p {
			margin: 0;
		}
	}
	&-imgs {
		margin: 12px 0;
		display: flex;
		align-items: center;
	}

	&-time {
		color: #949494;
		font-size: 10px;
	}
}