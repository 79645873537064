@import '/src/styles/AppDesign.scss';

html {
  * {
    font-family: $fontPrimary;
  }

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .gatsby-image-wrapper {
    user-select: none;
  }
}

.main-layout-wrapper {
  position: relative;
  min-width: 100%;
  width: 100%;
  scroll-behavior: smooth;
  overflow: hidden;
  min-height: 100vh;
}

.justify-align-center{
  display: flex;
  justify-content: center;
  align-items: center;

  @include smallScreen() {
    padding: 0 16px;
  }
}

.tabs-page-align {
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding-top: 60px;
  padding-bottom: 60px;

  @include smallScreen() {
    padding: 60px 16px;
  }
}

.width-control{
  width: 85%;
  max-width: 1028px;

  @include smallScreen() {
    width: 100%;
  }
}

.page-main{
  margin-top: $headerHeight;
  min-height: calc(100vh - #{$headerHeight});
  
  @include smallScreen() {
    min-height: calc(100vh - #{$mobileHeaderHeight});
  }
}

.page-wrapped-main {
  position: relative;
  margin-top: calc(#{$headerHeight} + #{$wrapperHeaderHeight});
  height: calc(100vh - #{$headerHeight} - #{$wrapperHeaderHeight} - 4px);
  max-height: calc(100vh - #{$headerHeight} - #{$wrapperHeaderHeight} - 4px);
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  @include smallScreen() {
    margin-top: calc(#{$mobileHeaderHeight} + #{$mobileWrapperHeaderHeight});
    height: calc(100vh - #{$mobileHeaderHeight} - #{$mobileWrapperHeaderHeight});
    max-height: calc(100vh - #{$mobileHeaderHeight} - #{$mobileWrapperHeaderHeight});
  }

  iframe {
    overflow: auto;
    width: 100%;
    height: 100%;
    appearance: none;
    outline: 0;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;

    &.mod-lock {
      overflow: hidden;
      pointer-events: none;
    }
  }

  .page-wrapped-lock {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35vw;
    padding: 24px;
    box-sizing: border-box;
    background: #9999;
    background: linear-gradient(0deg, #596365ff, #ffffff00);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .round-button {
      height: 50px;
      width: 100%;
      margin: 12px 0;
      font-weight: 700;
      cursor: pointer;
      box-shadow: 0 0 10px 2px #76e2d2;

      &:first-child {
        background-color: #fff;
        color: #000;
      }
      &:last-child {
        background-color: #76e2d2;
        color: #fff;
      }
    }
  }
}

.form-container{
  display: flex;
  justify-content: center;
}

.general-input{
  width: 500px;
  max-width: 90vw;
  // box-sizing: border-box;
  input{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #FBFAFF;
    padding:  16px 22px;
    margin-bottom: 5px;
    border-radius: 10px;
    align-items: 'center';
    border-width: inherit;
    text-decoration: none;
    outline: inherit;
  box-sizing: border-box;

  }
  
  // input{
  //   width: 100%;
  //   height: 100%;
  // }
}

.infinite-scroll-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}