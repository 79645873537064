.success-container {
	width: 100%;
	height: 100%;
	min-width: 50vw;
	padding: 48px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	h2 {
		font-size: 14px;
		font-weight: 700;
		color: #593565;
	}

	img {
		width: 40%;
		height: 40%;
		object-fit: contain;
	}

	>p {
		font-size: 14px;
		color: #959695;
		text-align: center;
		margin: 24px 0;
		width: 70%;
	}

	.round-button {
		width: 50%;
		background-color: #76e2d2;
		color: #fff;
		font-weight: 700;
		box-shadow: 0 8px 30px 0 #76e2d2;
	}
}