@import '../../../styles/AppDesign.scss';

.membership-page-content {
	margin: 30px auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, calc((100% - 36px * 3) / 3)));
	grid-template-rows: repeat(auto-fit, 200px);
	gap: 93px 36px;
}

.membership-page-try {
	order: 2;
	grid-column: 1 / -1;

	display: flex;
	justify-content: center;
	align-items: center;

	.round-button {
		width: calc(33% - 20px);
		min-width: 200px;
	}
}

.membership-page-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 50vw;

	img {
		width: 64px;
		height: 64px;
		flex: 0 0 64px;
		object-fit: contain;
	}
}

.membership-page-modal-title {
	margin: 12px 0;
	color: #46d3bd;
	font-size: 18px;
	font-weight: 600;
}

.membership-page-modal-desc {
	color: #696868;
	white-space: pre-wrap;
	text-align: left;
	line-height: 1.5;
}