@import '/src/styles/AppDesign.scss';

.login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px; 

  .round-button {
    width: 100%;
  }

  .form-error-container {
    position: absolute;
    bottom: -32px;
  }
}

.login-input-item {
  position: relative;
  margin: 13px 0;
}

.login-forgot {
  margin-bottom: 42px;
  color: #676767;
  text-decoration: underline;
}

.login-register-now {
  display: flex;
  flex-direction: column;
  align-items: center;

  >span {
    color: #9b99a9;
    margin: 12px 0;
  }
  >a {
    &:any-link {
      color: #595365;
      text-decoration: none;
    }
  }
  >button {
    color: #595365;
  }
}

.login-reset-password-modal {
	position: relative;
	margin: 24px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		color: #70677e;
		font-weight: 700;
		font-size: 20px;
		margin: 0 0 24px;
	}

	>p {
		color: #959695;
		font-size: 14px;
		padding: 0 24px;
	}

	.round-button {
		width: 100%;
		margin: 24px 0;
	}

	.form-error-container {
		position: absolute;
		bottom: -32px;
	}
}
