@import '../AppDesign.scss';

.homeTab {
	margin: 60px 0;
}

.newsRow {
	margin: 0 0 76px;
}

.newsHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 37px;
}

.newsSlider {
	width: calc(100% + 24px);
	margin-left: -12px;
}

.title {
	font-weight: 700;
	font-size: 24px;
	color: #212023;
	margin: 0;
}

.viewAll {
	font-weight: 700;
	font-size: 16px;
	text-align: right;
	color: #979797;
	&:any-link {
		text-decoration: none;
	}
}

.reportList {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: visible !important;
}

.reportLoading {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.reportItem {
	width: 100%;
	display: grid;
	grid-template-areas:
		'img title arrow'
		'img date arrow';
	grid-template-columns: 62px 1fr 26px;
	grid-template-rows: 1fr 1fr;
	gap: 0 20px;
	margin: 18px 0;
	border-radius: 8px;
	box-shadow: 4px 5px 19px 0 #5da79c42;
	padding: 16px 14px;
	box-sizing: border-box;

	&:any-link {
		color: unset;
		text-decoration: none;
	}

	img {
		border-radius: 100%;
		width: 62px;
		height: 62px;
		object-fit: cover;
		grid-area: img;

		margin-right: 20px;
	}

	span {
		grid-area: title;
		font-weight: 700;
		color: #232225;
		margin: auto 0;
		@include smallScreen () {
			@include textTrim(1);
		}
	}
	p {
		grid-area: date;
		color: #a4a4a4;
		margin: auto 0;
		font-size: 12px;
	}

	>div {
		grid-area: arrow;
		display: flex;
		justify-content: center;
		align-items: center;
		div {
			position: relative;
			width: 26px;
			height: 26px;
			border-radius: 100%;
			background-color: #76e2d2;

			&::before {
				position: absolute;
				top: 50%;
				left: 50%;
				display: inline;
				content: '';
				width: 6px;
				height: 6px;
				border-style: solid;
				border-color: #ffffff;
				border-width: 2px 2px 0 0;
				transform: translate(-6px, -50%) rotate(45deg);
			}
		}
	}	
}

.moreList {
	max-width: 100%;
	margin-top: 24px;
	display: grid;
	grid-template-rows: repeat(auto-fit, 1fr);
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 20px;
}

.moreListItem {
	box-shadow: 4px 5px 19px 0px #5da79c42;
	border-radius: 12px;
	min-height: 120px;
	padding: 12px;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;

	>span {
		font-weight: 600;
		flex: 0 0 60%;
		@include textTrim(2);
	}
}

.moreListItemImg {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 64px;
	width: 40%;
	flex: 0 0 40%;
	border-radius: 100%;

	img {
		width: 64px;
		height: 64px;
		object-fit: cover;
	}
}

.gradingDetailsModal {
	width: 85vw;
	max-width: 1024px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	text-align: center;
	box-sizing: border-box;
}