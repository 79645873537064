@import '../../styles/AppDesign.scss';

.product-types-list {
	max-width: 500px;
	margin: 0 auto;
}

.product-type-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;

	img {
		width: 64px;
		height: 64px;
		object-fit: contain;
		margin-bottom: 4px;
	}
}

.product-type-modal-title {
	color: #76e2d2;
}

.product-type-modal-desc {
	font-size: 12px;
	color: #949494;
	text-align: center;
	line-height: 1.5;
}