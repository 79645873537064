@import '../../../../styles/AppDesign.scss';

.entry-card {
	margin: 24px 0;
	width: 100%;
	display: grid;
	grid-template-areas:
		'icon title more'
		'icon subtitle more';
	grid-template-columns: 64px 1fr 24px;
	column-gap: 24px;
	row-gap: 16px;

	.entry-card-icon {
		width: 66px;
		grid-area: icon;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 66px;
			height: 66px;
			object-fit: cover;
			border-radius: 100%;
		}
	}
	.entry-card-title {
		margin: auto 0;
		grid-area: title;
		color: #302d34;
		@include textTrim(2);
	}
	.entry-card-subtitle {
		margin: auto 0;
		grid-area: subtitle;
		color: #89878d;
		font-size: 12px;
	}
	button {
		grid-area: more;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			height: 24px;
			object-fit: contain;
		}
	}
}
