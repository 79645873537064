@import '../../../../styles/AppDesign.scss';

.pending-code-card {
	height: 193px;
	border: 1px solid #dfdfdf;
	border-radius: 4px;

	display: grid;
	grid-template-areas:
		"usage title"
		"usage date"
		"usage button";
	grid-template-columns: 54px 1fr;
	gap: 24px 12px;
}

.pending-code-card-usage-container {
	height: 100%;
	grid-area: usage;;
}

.pending-code-card-usage {
	width: 100%;
	background-color: #000;
	color: #fff;
	border-radius: 6px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	padding: 6px 0;
	box-sizing: border-box;
	letter-spacing: 2px;
}

.pending-code-card-title {
	grid-area: title;
	font-weight: 600;
	color: #232225;
	font-size: 18px;
	@include textTrim(2);
	margin: auto 0;
}

.pending-code-card-date {
	grid-area: date;
	color: #595365;
	font-size: 12px;
	@include textTrim(1);
	margin: auto 0;
}

.pending-code-card-select {
	grid-area: button;
	width: 120px;
	height: 40px;
	background-color: #000;
	color: #fff;

	&:disabled {
		opacity: 0.5;
	}
}