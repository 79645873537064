@import '../../../../styles/AppDesign.scss';

.redeem-package-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.redeem-package-modal-title {
	color: #595365;
	font-size: 20px;
	font-weight: 700;
	margin: 0 0 10px;
}

.redeem-package-modal-text,
.redeem-package-modal-list {
	font-size: 12px;
	color: #949494;
	margin: 12px 0;
}

.redeem-package-modal-list {
	padding-left: 12px;
}

.redeem-package-modal-input {
	width: 100%;
	height: 60px;
	appearance: none;
	border: 0;
	outline: 0;
	padding: 12px 24px;
	box-sizing: border-box;
	background-color: #eee;
	margin-bottom: 8px;
	border-radius: 10px;
}

.redeem-package-modal-btn {
	width: 100%;
}
