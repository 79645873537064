@import '../../styles/AppDesign.scss';

.profile-page-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h2 {
		color: #090808;
		font-weight: 700;
		font-size: 24px;
		margin: 40px 0;
	}

	button {
		width: 24px;
		height: 24px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.edit-profile-modal {
	.round-button {
		width: 100%;
	}

	.form-error-container {
		position: absolute;
		bottom: -32px;
	}
}