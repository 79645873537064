@import '/src/styles/AppDesign.scss';

#product-details-page {
	display: flex;
	flex-direction: column;
	section {
		width: 100%;
		border-width: 0 0 2px 0;
		border-color: #191919;
		border-style: solid;
		padding: 36px 0;
		&:last-child {
			border-width: 0;
		}

		h3 {
			font-size: 16px;
			font-weight: 700;
			color: #595365;
			margin: 0;
		}
	}
}

.product-details {
	&-description-container {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;
		align-content: center;

		@include smallScreen() {
			flex-direction: column;
			align-content: center;
		}
	}

	&-image {
		width: 426px;
		flex: 0 0 426px;
		height: 426px;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	&-description {
		margin-left: 52px;
		h2 {
			color: #595365;
			font-size: 24px;
			font-weight: 700;
			margin: 0 0 32px;
		}
	}

	&-description-table {
		display: flex;
		flex-direction: column;
	}

	&-description-row {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 15px 0;
		box-sizing: border-box;
		border-style: solid;
		border-color: #d7d3d3;
		border-width: 0 0 1px 0;
		&:last-child {
			border-width: 0px;
		}
	}

	&-description-label {
		color: #595365;
		font-size: 12px;
		font-weight: 700;
	}

	&-description-value {
		text-align: right;
		color: #595365;
		font-size: 12px;
	}

	&-description-subtable {
		grid-row: 2 / 3;
		grid-column: 1 / -1;
	}

	&-description-subrow {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin: 14px 0;
	}
	&-description-sublabel,
	&-description-subvalue {
		font-size: 13px;
		color: #696868;
	}

	&-feedback-rating {
		display: flex;
		align-items: center;
		span {
			margin-right: 8px;
		}
	}
}

.product-details-pwc-testing {
	border-width: 2px 0 0 0;
	border-color: #191919;
	border-style: solid;
	.product-details-header {
		margin: 26px 0 !important;
		font-size: 16px;
		font-weight: 700;
		color: #595365;
		margin: 0;
	}
}

.product-details-see-more-button {
	color: #13c6c6;
	font-weight: 600;
	text-align: right;
}

.product-details-pwc-testing-items {
	display: flex;
	justify-content: space-between;
	align-content: center;
	width: 100%;
}

.product-details-pwc-testing-item {
	flex: 0 0 33%;
	width: 33%;
	display: flex;
	flex-direction: column;
	align-content: center;

	span {
		font-size: 12px;
		line-height: 1.5;
		text-align: center;
		color: #696868;
	}
}

.product-details-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: center;
}

.product-details-recommendations {
	background-color: #fbfbfb;

	.width-control {
		margin: 0 auto;
	}
}