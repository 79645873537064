@import '../../../../styles/AppDesign.scss';

.product-type-card {
	height: 111px;
	width: 100%;
	border-radius: 10px;
	box-shadow: 4px 5px 19px 0 #5da79c42;
	margin: 8px;
	padding: 18px 16px;
	box-sizing: border-box;

	display: grid;
	grid-template-areas:
		'img title button'
		'img subtitle button';
	grid-template-columns: 62px 1fr 26px;
	gap: 8px 18px;

	&:any-link {
		text-decoration: none;
	}
}

.product-type-card-img {
	grid-area: img;
	width: 100%;
	height: 100%;
	max-height: 62px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 100%;
	}
}

.product-type-card-title {
	grid-area: title;
	color: #232225;
	font-weight: 600;
	font-size: 18px;
	margin: auto 0;
	@include textTrim(1);
}

.product-type-card-subtitle-container {
	grid-area: subtitle;
}

.product-type-card-subtitle {
	color: #696868;
	font-size: 14px;
	@include textTrim(2);
}

.product-type-card-detail {
	color: #13c6c6;
	font-size: 12px;
	text-decoration: underline;
	@include textTrim(1);
}

.product-type-card-button {
	grid-area: button;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}