$max-width-desktop: 1440px;
$screen-md-max: 991px;
$screen-sm-max: 575px;

$homeSidePadding: 114px;
$mobileSidePadding: 14px;

$headerHeight: 100px;
$mobileHeaderHeight: 76px;

$footerHeight: 105px;
$mobileFooterHeight: 76px;

$wrapperHeaderHeight: 50px;
$mobileWrapperHeaderHeight: 50px;

// Max device (large desktop, >1440px)
@mixin desktop-and-up {
    @media screen and (min-width: $max-width-desktop + 1px) {
        @content;
    }
}

// Large device (small desktop, 992px - 1440px)
@mixin desktop {
    @media screen and (max-width: $max-width-desktop) and (min-width: $screen-md-max) {
        @content;
    }
}

// Md device (tablet, 576px - 991px)
@mixin tablet {
    @media screen and (max-width: $screen-md-max) {
        @content;
    }
}

// Small device (mobile, 0px = 575px)
@mixin mobile {
    @media screen and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin smallScreen {
    @include tablet() {
        @content;
      }
      @include mobile() {
        @content;
      }
}

@mixin largeScreen {
    @include desktop-and-up() {
        @content;
    }
    @include desktop() {
        @content;
    }
}
