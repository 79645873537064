@import '/src/styles/AppDesign.scss';

.round-button {
	cursor: pointer;
	position: relative;
	appearance: none;
	padding: 12px;
	margin: 0;
	border: 0;
	outline: 0;
	border-radius: 28px;
	background-color: #76e2d2;
	color: $white;
	font-size: 14px;
	box-sizing: border-box;
	box-shadow: 0 8px 30px 0 #76e2d2;
	font-weight: 700;

	display: flex;
	justify-content: center;
	align-items: center;

	&.mod-disabled {
		filter: grayscale(0.5);
		cursor: not-allowed;
	}

	&.mod-loading {
		color: transparent !important;
		cursor: wait;
		p {
			color: transparent !important;
		}
	}

	&:active {
		filter: brightness(0.9);
	}

	&.mod-disabled:active {
		filter: grayscale(0.5);
	}

	text-decoration: none;

	p {
		position: relative;
		margin: 0 16px;
		text-decoration: none;
	}
}

.round-button-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}