@import '../../../styles/AppDesign.scss';

.saved-items-page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	
	h2 {
		color: #090808;
		font-size: 24px;
		font-weight: 700;
	}
}

.saved-items-list-card {
	margin: 24px 0;
	width: 100%;
	display: grid;
	grid-template-areas:
		'icon title more'
		'icon subtitle more';
	grid-template-columns: 64px 1fr 24px;
	column-gap: 24px;
	row-gap: 16px;

	.saved-items-list-icon {
		width: 66px;
		grid-area: icon;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 66px;
			height: 66px;
			object-fit: cover;
			border-radius: 100%;
		}
	}
	.saved-items-list-card-title {
		margin: auto 0;
		grid-area: title;
		color: #302d34;
		@include textTrim(2);
	}
	.saved-items-list-card-subtitle {
		margin: auto 0;
		grid-area: subtitle;
		color: #89878d;
		font-size: 12px;
	}
	button {
		grid-area: more;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			height: 24px;
			object-fit: contain;
		}
	}
}
