@import '/src/styles/AppDesign.scss';

.item-slider {
	width: 100%;

	.slick-prev:before,
	.slick-next:before {
		position: absolute;
		top: 50%;
		left: 50%;
		border-color: #606060;
		border-style: solid;
		width: 17px;
		height: 17px;
		display: inline;
		opacity: 1;
		font-family: $fontPrimary;
		content: '';
		transform: rotate(45deg) translate(-50%, -25%);
	}

	.slick-prev:before {
		border-width: 0 0 1px 1px;
	}
	.slick-next:before {
		border-width: 1px 1px 0 0;
	}

	.slick-disabled:before {
		opacity: 0.2;
	}
}