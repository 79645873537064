@import '/src/styles/AppDesign.scss';

#wrapper-header {
	position: absolute;
	top: $headerHeight;
	left: 0;
	height: $wrapperHeaderHeight;
	width: 100vw;
	background-color: #fff;
	box-sizing: border-box;
	z-index: 10;

	border-top: 1px solid #e0e0e0;

	.home-section-container {
		height: 100%;
	}

	@include smallScreen() {
		top: $mobileHeaderHeight;
		height: $mobileWrapperHeaderHeight;
	}
}

.wrapper-header-content {
	width: 100%;
	display: grid;
	grid: #{$wrapperHeaderHeight} / 32px 1fr 32px;

	h2 {
		text-align: center;
		margin: auto 0;
		font-weight: 600;
	}

	button {
		width: 32px;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
