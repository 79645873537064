@import '../../styles/AppDesign.scss';

.news-page-tab {
	padding-top: 48px;
	width: 100%;
}

.news-page-tab-scroll {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

	&.mod-long {
		grid-template-columns: repeat(auto-fill, minmax(300px, 50%));
	}
}

.news-page-tab-loading {
	grid-column: 1 / -1;
	min-height: 274px;
	display: flex;
	justify-content: center;
	align-items: center;
}