@import '../AppDesign.scss';

.header {
	margin: 70px 0 24px;
	width: 40%;
}

.title {
	color: #212023;
	font-weight: 700;
	font-size: 24px;
}

.storeContainer {
	width: 100%;
	min-height: 350px;
	border: 1px solid #eeebeb;

	display: flex;
	align-items: stretch;
	margin-bottom: 30px;
}

.storeSidebar {
	width: 180px;
	flex: 0 0 180px;
	background-color: #eeebeb;
	padding: 18px 0;
	box-sizing: border-box;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.categoryButton {
	width: 100%;
	height: 48px;
	flex: 0 0 48px;
	color: #949494;
	font-weight: 600;
}

.activeCategory {
	background-color: #76e2d2;
	color: #fff;
	font-weight: 700;
}

.storeContents {
	flex: 1 0 auto;
	width: calc(100% - 180px);
	padding: 12px 24px;
	box-sizing: border-box;
	max-height: 350px;
	overflow: auto;
}

.storeList {
	overflow: hidden !important;
	display: grid;
	grid-template-rows: repeat(auto-fill, 150px);
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 24px 44px;
}

.storeItem {
	max-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&:any-link {
		text-decoration: none;
	}

	img {
		flex: 0 0 60%;
		height: 60%;
		width: 100%;
		object-fit: contain;
	}
	span {
		color: #696868;
		font-weight: 600;
		text-align: center;
		@include textTrim(2);
	}
}

.storeListCenter {
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
	align-items: center;
}