@import '/src/styles/AppDesign.scss';

#common-header {
	position: absolute;
	top: 0;
	left: 0;
	height: $headerHeight;
	width: 100vw;
	background-color: #fff;
	box-sizing: border-box;
	z-index: 10;

	.home-section-container {
		height: 100%;
	}

	@include smallScreen() {
		height: $mobileHeaderHeight;
	}
}

.common-header-container{
	height: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	border-bottom: 1px solid $borderGray;

	.common-header-flex{
		display: flex;
    align-items: center;
	}
}

.common-header-logo {
	user-select: none;
	// position: absolute;
	// top: calc(50% - (#{$headerHeight} - 32px) / 2);
	// left: $homeSidePadding;
	height: calc(#{$headerHeight} - 32px);
	width: calc(#{$headerHeight} - 32px);

	img{
		width: 100%
	}

	@include smallScreen() {
		top: calc(50% - (#{$mobileHeaderHeight} - 32px) / 2);
		left: $mobileSidePadding;
		height: calc(#{$mobileHeaderHeight} - 32px);
		width: calc(#{$mobileHeaderHeight} - 32px);
	}
}

.common-header-top {
	width: 100%;
	height:  100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	// padding-top: 16px;
	

	@include smallScreen() {
		display: none;
	}
}

.common-header-items {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 8px 0;
	box-sizing: border-box;
}

.common-header-item {
	margin-left: 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #70677e;

	&.mod-active {
		font-weight: 700;
		color: #76e2d2;
	}

	&:any-link {
		text-decoration: none;
	}

	.common-header-item-icon {
		position: relative;
		width: 24px;
		height: 24px;
		object-fit: contain;
		border-radius: 100%;
	}
	.gatsby-image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
	}
	p {
		margin: 0 0 0 4px;
		color: #231f20;
		line-height: 24px;
	}
	.round-button {
		height: 49px;
		width: 179px;
		box-sizing: border-box;
		background-image: linear-gradient(to right, #92c54b 5%, #38a573 50%, #2d96ae 97% );
		p {
			color: #fff;
		}
	}
}

.common-header-bottom {
	user-select: none;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	@include smallScreen() {
		display: none;
	}
}

.common-header-link {
	position: relative;
	color: #231f20 !important;
	margin: 0 0 0 64px;
	text-decoration: none;

	a {
		color: #231f20 !important;
		text-decoration: none;
	}


	&.mod-active {
		border-bottom: 2px solid #231f20;
	}
}

.common-header-profile {
	position: relative;
	margin: 0 0 0 64px;
	cursor: pointer;
	.gatsby-image-wrapper {
		position: relative;
	}
}

.common-header-profile-menu {
	position: absolute;
	top: calc(100% + 28px);
	left: calc(50% - 125px);
	width: 250px;
	padding: 35px 50px;
	box-sizing: border-box;
	background-color: #fff;
	box-shadow: 4px 4px 20px 0 rgba(48, 63, 55, 0.12);
	border: 1px solid #e3e8e8;
	border-radius: 4px;

	flex-direction: column;
	align-items: center;

	&::before {
		content: '';
		display: inline;
		position: absolute;
		top: -20px;
		left: calc(50% - 12px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 12px 20.8px 12px;
		border-color: transparent transparent #e3e8e8 transparent;
	}
	&::after {
		content: '';
		display: inline;
		position: absolute;
		top: -18px;
		left: calc(50% - 11px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width:  0 11px 19.1px 11px;
		border-color: transparent transparent #fff transparent;
	}
}

.common-header-profile-link {
	width: 100%;
	margin-bottom: 16px;
	color: #231f20 !important;
	text-decoration: none !important;
	text-align: left;

	&:last-child {
		margin-bottom: 0;
	}
}

.common-header-mobile-menu-button {
	display: none;

	@include smallScreen() {
		display: flex;
	}
	justify-content: center;
	align-items: center;
	margin-left: auto;
	width: 32px;
	height: 32px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.common-mobile-menu-container {
	position: relative;
	height: 100vh;
	width: 100vw;

	display: flex;
	justify-content: center;
	align-items: center;
}

.common-mobile-menu-close {
	position: absolute;
	top: 24px;
	right: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.common-mobile-menu-list {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.common-mobile-menu-item {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #70677e;
	font-size: 24px;
	line-height: 1.5;

	&.mod-active {
		font-weight: 700;
		color: #76e2d2;
	}

	&:any-link {
		text-decoration: none;
	}

	&:last-child {
		margin-top: 24px;
	}

	img {
		width: 32px;
		height: 32px;
		object-fit: contain;
		margin-right: 12px;
	}
}
