@import '../../../../../styles/AppDesign.scss';

.membership-card {
	position: relative;
	height: 150px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 4px 5px 19px 0 #5da79c42;
	padding: 16px 23px;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.membership-card-background {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
}

.membership-card-title {
	margin-bottom: 11px;
	font-weight: 600;
	font-size: 14px;
	@include textTrim(1);
}

.membership-card-desc {
	@include textTrim(3);
	color: #696868;
	font-size: 12px;
	line-height: 20px;
}

.membership-card-details {
	text-align: left;
	text-decoration: underline;
	margin-top: auto;

	font-size: 12px;
	color: #13c6c6;
}