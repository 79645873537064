@import '/src/styles/AppDesign.scss';

.coming-soon {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		margin: 5%;
		width: 30%;
		height: 30%;
		object-fit: contain;

		@include smallScreen() {
			width: 60%;
			height: 60%;
			margin: 5% 0;
		}
	}
}
