@import '../../../../styles/AppDesign.scss';

.my-report-card {
	&:any-link {
		text-decoration: none;
	}

	display: flex;
	flex-direction: column;
	min-height: 400px;
	height: 400px;
}

.my-report-card-img {
	flex: 0 0 250px;
	object-fit: cover;
	width: 100%;
	border-radius: 10px;
}

.my-report-card-content {
	flex: 0 0 calc(25% - 8px);
	margin-top: 8px;

	display: grid;
	grid-template-areas:
		"categoryIcon categoryName"
		"categoryIcon expiryDate"
		"reportName reportName";
	grid-template-columns: 35px 1fr;
	grid-template-rows: 16px 16px 1fr;
	gap: 2px 10px;
}

.my-report-card-category-icon {
	grid-area: categoryIcon;
	width: 35px;
	height: 35px;
	object-fit: contain;
}

.my-report-card-category-name {
	@include textTrim(1);
	grid-area: categoryName;
	font-weight: 600;
	color: #232225;
	margin: auto 0;
}

.my-report-card-expiry {
	@include textTrim(1);
	grid-area: expiryDate;
	font-size: 12px;
	color: #a4a4a4;
	margin: auto 0;
}

.my-report-card-title {
	@include textTrim(3);
	grid-area: reportName;
	font-weight: 700;
	color: #232225;
	margin: 10px 0;
}