@import '../../../../styles/AppDesign.scss';

.pending-report-card {
	position: relative;
	height: 133px;
	border: 1px solid #dfdfdf;
	border-radius: 4px;
}

.pending-report-card-content {
	overflow: hidden;
	&:any-link {
		text-decoration: none;
	}

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-areas:
		"img title arrow"
		"img subtitle arrow";
	grid-template-columns: 133px 1fr 24px;
	gap: 16px;
}

.pending-report-card-img {
	width: 133px;
	height: 100%;
	object-fit: cover;
	grid-area: img;
}

.pending-report-card-title {
	@include textTrim(1);
	color: #232225;
	font-weight: 700;
	grid-area: title;
	margin: auto 0;
}

.pending-report-card-subtitle {
	@include textTrim(2);
	color: #515151;
	line-height: 1.5;
	grid-area: subtitle;
	margin: auto 0;
}

.pending-report-card-arrow {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: arrow;

	img {
		width: 100%;
		height: 24px;
		object-fit: contain;
	}
}

.pending-report-card-remove {
	position: absolute;
	top: -4px;
	left: -4px;
	color: #fff;
	background-color: #000;
	border-radius: 100%;
	display: inline;
	width: 12px;
	height: 12px;

	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		content: '';
		height: 1px;
		width: 6px;
		background-color: #fff;
	}
	&::before {
		transform: translateX(-50%) rotate(-45deg);
	}
	&::after {
		transform: translateX(-50%) rotate(45deg);
	}
}

.pending-report-card-unread {
	position: absolute;
	top: 8px;
	right: 8px;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	content: '';
	background-color: #dfdfdf;
}