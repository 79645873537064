@import '/src/styles/AppDesign.scss';

.rating-meter {
	width: 100px;
	height: 16px;
	display: grid;
	grid-template-columns: 80px 1fr;
	place-items: center;
	span {
		font-size: 12px;
		font-weight: 600;
		color: #595365;
	}
}

.rating-meter-stars {
	display: inline-flex;
	align-items: center;
	margin-right: 8px;
	height: 16px;
}

.rating-meter-star {
	display: grid;
	place-items: center;
	position: relative;
	width: 16px;
	height: 16px;
	background: #f7b500;
	clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.rating-meter-star-inner {
	width: 10px;
	height: 10px;
	background: #fff;
	clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}