@import '../../styles/AppDesign.scss';

.item-card {
	&:any-link {
		color: #595365;
		text-decoration: none;
		font-weight: 700;
		line-height: 1.5;
	}
}

.item-card-box {
	height: 274px;
	border-radius: 8px;
	box-shadow: 4px 5px 19px 0px #5da79c42;

	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 12px;
}

.item-card-img-container {
	position: relative;
	width: 100%;
	height: 65%;
	flex: 0 0 65%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.item-card-title-container {
	position: relative;
	border-radius: 8px;
	width: 100%;
	// Extra 8px for overlap effect
	height: calc(35% + 8px);
	flex: 0 0 calc(35% + 8px);
	transform: translateY(-8px);
	padding: 24px 32px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #ffffff;

	.item-card-title,
	.item-card-subtitle {
		max-width: 100%;
		@include textTrim(1);

		&.mod-multi {
			-webkit-line-clamp: 2;
		}
		&.mod-shrink {
			max-width: 80%;
		}
	}

	.item-card-subtitle {
		color: #919098;
		font-weight: 600;
		font-size: 12px;
		margin-top: 10px;
	}

	.item-card-arrow {
		position: absolute;
		top: 18px;
		right: 24px;
		display: inline;
		width: 36px;
		height: 36px;
		border-radius: 100%;
		background-color: #76e2d2;

		&::before {
			display: inline;
			content: '';
			position: absolute;
			top: 32%;
			left: 25%;
			width: 10px;
			height: 10px;
			border-color: #ffffff;
			border-style: solid;
			border-width: 2px 2px 0 0;
			transform: rotate(45deg);
		}
	}
}