@import '/src/styles/AppDesign.scss';

.search-bar {
	height: 40px;
	min-width: 300px;

	position: relative;
	background-color: #f0f7f6;
	border-radius: 20px;

	&::before,
	&::after {
		position: absolute;
		display: inline;
		content: '';
		border-style: solid;
		border-color: #b3d0c1;
		border-width: 2px;
	}

	&::before {
		top: 10px;
		left: 14px;
		width: 10px;
		height: 10px;
		border-radius: 100%;
	}
	&::after {
		top: 24px;
		left: 22px;
		width: 10px;
		height: 1px;
		border-width: 2px 0 0 0;
		transform: rotate(45deg);
	}

	input {
		outline: 0;
		box-sizing: border-box;
		width: 100%;
		height: 40px;
		line-height: 40px;
		appearance: none;
		background-color: transparent;
		border: 0;
		padding: 0 40px;

		font-size: 14px;
		
		&::placeholder {
			color: #b3d0c1;
			font-weight: 600;
			font-size: 14px;
		}
	}
}