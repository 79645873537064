@import '../AppDesign.scss';

.profileTab {
	margin: 60px 0;
	width: 100%;
}

.profileInfoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 350px;
	margin: 0 auto;
}

.profilePic {
	width: 114px;
	height: 114px;
	border-radius: 100%;
	object-fit: cover;
}

.profileName {
	margin: 26px 0;
	color: #67dac0;
	font-weight: 700;
	font-size: 24px;
}

.memberId {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px 0;

	>span {
		font-weight: 700;
		&:first-child {
			color: #9b97a1;
		}
		&:last-child {
			color: #302d34;
		}
	}
}

.profileInfoList {
	width: 100%;
	border-top: 2px solid #191919;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.profileInfoListItem {
	padding: 0 8px;
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	height: 80px;
	flex: 0 0 80px;

	&:any-link {
		text-decoration: none;
	}

	>img:first-child {
		width: 16px;
		height: 16px;
		object-fit: contain;
		margin-right: 22px;
	}

	>span {
		font-weight: 700;
		color: #67dac0;
		margin-right: auto;
	}

	>img:last-child {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}
}

.profileMailList {
	width: 100% !important;
}

.profileMailCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.mailCard {
	position: relative;
	display: flex;
	align-items: center;
	height: 111px;
	width: 100%;
	max-width: 100%;
	border-bottom: 1px solid #191919;

	&:last-child {
		border-bottom: 0;
	}

	>img {
		width: 63px;
		height: 63px;
		object-fit: cover;
		border-radius: 100%;
		margin-right: 24px;
	}
}

.mailCardUnread::after {
	position: absolute;
	top: calc(50% - 3px);
	right: 2px;
	width: 6px;
	height: 6px;
	content: '';
	background-color: #76e2d2;
	border-radius: 100%;
}

.mailBody {
	margin-right: 24px;
	font-weight: 700;
	color: #302d34;

	@include textTrim(2);
}

.mailTime {
	color: #89878d;
	margin-right: 14px;
	margin-left: auto;
}