@import '../AppDesign.scss';

.redemptionTab {
	margin: 60px 0;
}

.redeemCodeSection {
	margin: 0 auto 114px;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include smallScreen() {
		width: 100%;
	}
}

.redeemCodeDesc {
	white-space: pre-wrap;
	text-align: center;
	color: #333c46;
	font-weight: 600;
	line-height: 1.5;
	margin: 0 0 32px;
	padding: 0 12px;
	box-sizing: border-box;
}

.redeemCodeInput {
	appearance: none;
	border: 0;
	outline: 0;
	width: 100%;
	height: 57px;
	background-color: #fafbff;
	border-radius: 10px;
	margin: 10px 0;
	padding: 0 12px;
	box-sizing: border-box;
}

.roundButton {
	width: 100%;
}

.recentRedeemSection {
	width: 100%;
	padding: 0 40px;
	box-sizing: border-box;

	@include smallScreen() {
		padding: 0;
	}
}

.recentRedeemHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.recentRedeemTitle {
	font-weight: 700;
	color: #333c46;
}

.seeAll {
	color: #13c6c6;
	font-weight: 600;
	display: flex;
	align-items: center;

	span {
		margin-right: 4px;
	}

	&::after {
		content: '';
		width: 8px;
		height: 8px;
		border-top: 1px solid #13c6c6;
		border-right: 1px solid #13c6c6;
		transform: rotate(45deg) translateY(-2px);
	}
}

.redemptionTabList {
	margin: 24px auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 24px;

	@include smallScreen() {
		grid-template-columns: 1fr;
	}
}
