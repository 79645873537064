@import '../../styles/AppDesign.scss';

.long-item-card {
	position: relative;
	height: 105px;
	border-radius: 10px;
	box-shadow: 4px 5px 19px 0 #5da79c42;
	margin: 8px;
}

.long-item-card-content {
	&:any-link {
		text-decoration: none;
	}

	display: flex;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
}

.long-item-card-img {
	flex: 0 0 105px;
	height: 105px;
	width: 105px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.mod-round {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 64px;
			height: 64px;
			border-radius: 100%;
		}
	}
}

.long-item-card-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1 0 calc(100% - 105px - 36px);
	padding: 14px;
	box-sizing: border-box;
	background-color: #fff;
	text-align: left;
	min-height: 100%;
}

.long-item-card-title {
	color: #232225;
	font-weight: 600;
	@include textTrim(2);
}

.long-item-card-subtitle {
	color: #89878d;
	margin-top: 12px;
	font-size: 12px;
	@include textTrim(1);
}

.long-item-card-close-button {
	position: absolute;
	top: -6px;
	right: -12px;
	width: 24px;
	height: 24px;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.long-item-card-arrow {
	flex: 0 0 24px;
	margin-right: 12px;
	height: 100%;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}
}