.form-error-container-content {
	display: grid;
	grid-template-columns: 24px auto;
	column-gap: 8px;
	margin-top: 5px;

	img {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}
}

.form-error-container-content-children {
	color: #e45841;
	font-size: 14px;
	grid-column: 2;
	grid-row: 1;
	line-height: 24px;
}