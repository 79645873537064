@import '/src/styles/AppDesign.scss';

.error-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 26px;
	box-sizing: border-box;
	min-width: 30vw;

	.round-button {
		border: 1px solid #2dbdb6;
		color: #2dbdb6;
		height: 56px;
		width: 80%;
		background-color: #fff;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: #f0f0f0;
		}
	}
}

.error-modal-msg {
	color: #231f20;
	font-size: 24px;
	margin: 26px 0;
}