@import '../../styles/AppDesign.scss';

#settings-page {
	display: flex;
	align-items: center;
	flex-direction: column;

	section {
		display: flex;
		flex-direction: column;
		width: 70%;
		padding: 40px 0;
		box-sizing: border-box;

		border-bottom: 2px solid #191919;

		&:last-child {
			border-bottom: 0;
		}
	}

	h3 {
		color: #252327;
		font-weight: 700;
		font-size: 24px;
	}
}

.settings-table {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.settings-label {
	position: relative;
	appearance: none;
	border: 0;
	outline: 0;
	padding: 0;
	text-align: left;
	margin: 18px 0;
	font-weight: 600;
	color: #959695;
	grid-column: 1 / 3;
	&:first-child {
		grid-column: 1 / 2;
	}
	&:any-link {
		text-decoration: none;
	}
	&.mod-arrow {
		grid-column: 1 / 3;
		&::after {
			position: absolute;
			right: 0;
			top: calc(50% - 10px);
			width: 20px;
			height: 20px;
			content: '';
			background-image: url('../../assets/images/nextBtn.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}
	}
}

.settings-label-radios {
	flex-direction: column !important;
	align-items: stretch !important;
	max-width: 200px;
	margin-left: auto;
}